
@mixin es_select-validation-state($state, $color) {
    .was-validated &:#{$state},
    &.is-#{$state} {
        + .es_select {
            > .es_select-header.form-control {
                border-color: $color;
    
                &:focus {
                    border-color: $color;
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
    

            
            }

            > .es_select-body {
                .es_select-filter-container,
                ul.es_select-options {
                    border-color: $color;
                }

                
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
            
        
        }
    }

}

select.es_select, select.es_select-custom, select.es_select-organ, select.es_select-meps {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    height: 0;
    display: block;
}

.es_select, .es_select-custom {
    position: relative;
    width: 100%;

    .es_select-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .es_select-value {
            display: block;
            z-index: 1;

            i {
                display: none;
            }

            &[data-value-truncated="true"] {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;

           
            }

            
        }
        
    }

    &.disabled {
        pointer-events: none;
        .es_select-header {
            @if ($darkMode) {
                background: $gray-800;
                color: $gray-500

            } @else {
                background: $gray-200;
            }
        }
    }

  
    &.multiple {
        .es_select-header {
            .es_select-value {
                button {
                    border: 1px solid $border-color;
                    padding: $input-padding-y-sm $input-padding-x-sm;
                    display: inline-block;
                    margin-right: $spacer * .25;
                    margin-bottom: $spacer * .25;
                    border-radius: $border-radius-sm ;
                    @if ($darkMode) {
                        background-color: $gray-800;
                        color: $white;

                    } @else {
                        background-color: $white;

                    }
                    &:hover, &:focus {
                        @if ($darkMode) {
                            background-color: $gray-900;

                        } @else {
                            background-color: $gray-200;

                        }
                    }

                    i {
                        display: inherit;
                    }

                }
            }
        }
    }

    &[data-position="static"] {
        .es_select-body {
            position: inherit;
        }
    }

    &[data-options-no-wrap="true"] {
        .es_select-body {
            .es_select-options {
                li {
                    white-space: nowrap;
                }
            }
        }
    }

    .es_select-body {
        display: none;

        @if ($darkMode) {
            background: $gray-900;
        } @else {
            background: $white;

        }
        position: absolute;
        z-index: 100;
        width: 100%;

        .es_select-filter-container {
            position: relative;
            display: none;
            padding: $input-padding-y $input-padding-x;
            border-left: $input-border-width solid $primary;
            border-right: $input-border-width solid $primary;
            border-top: $input-border-width solid $primary;

            .es_select-filter-label {
                position: absolute;
                padding: .25rem .5rem;
                z-index: 1;
                pointer-events: none;
                margin: 0;
                font-size: .8125rem;
                transition: .25s
            }

            .es_select-filter-value {
                display: block;
                z-index: 1;
                border-right: none;
                border-color: $border-color;

                &:focus {
                    box-shadow: inherit;
                    border-color: $primary;
                    ~ .input-group-append .input-group-text {
                        border-color: $primary;
                    }

                    
                }

                &.has-value + .es_select-filter-label {
                    top: -6px;
                    font-size: .7rem;
                    @if ($darkMode) {
                        background: $gray-900;

                    } @else {
                        background: $white;

                    }
                    left: 5px;
                    color: $primary;
                    padding: 0 2px;
                }
            }

            .input-group-append {
                .input-group-text {
                    background: transparent;

                    .spinner {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }
            }

            &:not(.d-block) + ul.es_select-options {
                border-width: 1px

            }
        }

        ul.es_select-options {
            padding: 0;
            margin: 0;
            list-style: none;

            border-style: solid;
            border-width: 0 1px 1px 1px;
            border-color: $primary;
            overflow: auto;
            max-height: 340px;

           

            li {
                padding: $input-padding-y $input-padding-x;
                position: relative;

                &:not(:first-child) {
                    span {
                        &:after {
                            display: block;
                            content: "";
                            position: absolute;
                            left: $input-padding-x;
                            right: $input-padding-x;
                            top: 0;
                            background: $border-color;
                            height: 1px;
                        }
                    }
                }

                &.es_select-options-group {
                    font-weight: bold;
                }

                &.es_select-options-item {
                    cursor: pointer;

                    .es_select-options-organ {
                    
                    }

                    .es_select-mep-picture {
                        img {
                            width: 50px;
                        }
                    }

                    &.nopic {
                        .es_select-mep-picture {
                            display: none;
                        }
                    }

                    &[data-selected="true"] {
                        @if ($darkMode) {
                            background: $gray-900;

                        } @else {
                            background: $gray-200;

                        }

                        color: $primary;

                        span:after {
                            background: $primary !important;
                        }

                        + li span:after {
                            background: $primary !important;
                        }

                    }



                    &:hover, &:focus {
                        @if ($darkMode) {
                            background: $black;
                        } @else {
                            background: $gray-201;

                        }
                        outline: none;

                    }

                    &.disabled {
                        color: $text-muted;
                        background: transparent;
                        cursor : inherit;
                    }

                }
            }
        }

    }

    &.active {
    
        .es_select-header {
            &:after {
                content: "";
                display: block;
                height: 3px;
                @if ($darkMode) {
                    background: $gray-900;
    
                } @else { 
                    background: $white;
    
                }
                position: absolute;
                width: 100%;
                left: 0;
                z-index: 101;
            }

            border-color: $primary;
        }

        &[data-direction="top"] {
            .es_select-header {
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                border-top: none;
                @if ($darkMode) {
                    background: $gray-900;

                } @else { 
                    background: $white;

                }

                &:after {
                    top: -2px;
                }

            }
        }

        &[data-direction="bottom"] {
            .es_select-header {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom: none;
                @if ($darkMode) {
                    background: $gray-900;

                } @else { 
                    background: $white;

                }
    
                &:after {
                    bottom: -2px;
                }
            }
        }

        

        .es_select-body {
            display: block;
        }
    }


    @include es_select-validation-state("valid", $success );
    @include es_select-validation-state("invalid", $danger );
}

