

//──── ERPL Tree Select ──────────────────────────────────────────────────────────────────
.erpl_tree  {
	position: relative
}

.erpl_tree .erpl_tree-field:hover  {
	cursor: pointer;
}


.erpl_tree[data-open="true"] .erpl_tree-content {
	display: block;
}

.erpl_tree[data-position="absolute"] .erpl_tree-content  {
	position: absolute;
	left: 0;
	right: 0;
	@if ($darkMode) {
		background: $gray-900;
	} @else { 
		background: $white;
	 }
	z-index: 998;
}

.erpl_tree[data-show-icon="false"] ul li .erpl_tree-icon-status {
	display: none !important;
}

.erpl_tree  .erpl_tree-content {
	margin-top: -1px
}

.erpl_tree[data-expanded="true"]  .erpl_tree-field .form-control,
.erpl_tree[data-expanded="true"]  .erpl_tree-field .input-group-append .erpl_icon {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-color: $primary;
}

.erpl_tree  ul {
	list-style: none;
	padding: 0;
}

.erpl_tree ul > li[tabindex]:focus {
	outline: none;
	background: $gray-100
}



.erpl_tree ul > li {
	border-bottom: 2px dotted $border-color;
	padding: 10px 0 10px 10px;
}

.erpl_tree ul > li:last-child {
	border-bottom: none;
}


.erpl_tree ul li.active > .erpl_tree-item {
	color: $primary; 
}

// .erpl_tree ul > li[tabindex].active:focus {
// 	// background: none;
// }

.erpl_tree ul li.selected > .erpl_tree-item {
	color: $success; 
}

.erpl_tree ul li:not(.selected) > .erpl_tree-item.highlight {
	font-style: italic;
	color: $warning
}
.erpl_tree ul li[data-erpl-tree-selectable="false"][data-has-children="false"] > .erpl_tree-item.highlight {
	opacity: .8
}

.erpl_tree ul > li[tabindex]:focus {
	outline: none;
	@if ($darkMode) {
		background: $gray-800
	} @else { 
		background: $gray-100
	 }
}


.erpl_tree ul li .erpl_tree-icon-status {
	float: right;
	border: 1px solid $border-color;
	width: 17px;
	height: 17px; 
	border-radius: 50%;
	margin-right: 5px;
	margin-left: 5px;
}

.erpl_tree ul li ul {
	border-left: 1px solid $border-color;
	margin-left: 4px;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: none;
}

.erpl_tree ul li ul li {
	border-bottom: 1px dotted $border-color;
}

.erpl_tree ul li[data-has-children="false"][data-erpl-tree-selectable="false"] > .erpl_tree-item {
	color: $gray-500; 
}

.erpl_tree ul li[data-has-children="true"] > .erpl_tree-item,
.erpl_tree ul li[data-erpl-tree-selectable="true"] > .erpl_tree-item,
.erpl_tree .erpl_tree-search .input-group-append .erpl_icon.erpl_icon-more-light {
	cursor: pointer;
}

// FIX IE - Clear button
.erpl_tree .erpl_tree-search input::-ms-clear {
    display: none;
}