//──── ERPL CRITERIA ────────────────────────────────────────────────────────────────────
.erpl_criteria .btn-default {
	
	@if ($darkMode) {
		color: $gray-900;
	} @else {
		color: $primary
	}
	white-space: normal
}
.erpl_criteria .btn-default:hover {
	border-color: $primary;
	background: $primary; 
	@if not ($darkMode) {
		color: $white
	}
}

.erpl_criteria .btn-default > * {
	vertical-align: middle
}

.erpl_criteria .btn-default:hover i.erpl_icon-close-light:after {
	@if not ($darkMode) {
		background-position: -90px -212px;
	}
}