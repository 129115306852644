//──── ERPL DOCUMENTS ────────────────────────────────────────────────────────────────────
.erpl_document {
    position: relative;
    .erpl_document-subtitle {
        &[data-separator] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    color: $gray-900;
                }
            }
        }
        &[data-separator="-"] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " - ";
                }
            }
        }
        &[data-separator="|"] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " | ";
                    margin-right: 0.25rem;
                    margin-left: 0.25rem;
                    vertical-align: top;
                 
                  
            
                }
            }
        }
        &[data-separator=","] {
            [class^="erpl_document-subtitle-"]:not(:first-child) {
                &:before {
                    content: " , ";
                       margin-right: 0.15rem;
              
                }
            }
        }
    }
}



.erpl_format-doc, .erpl_format-pdf {
  @if ($darkMode) {
    color:$gray-300;

  } @else { 
    color:$secondary;

   }
}

