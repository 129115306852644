//──── glossary ────────────────────────────────────────────────────────────────

.erpl_glossary {
    
    .erpl_list-letters {
        padding: 0;

        .erpl_letter {
            display: inline-block;
            text-align: center;
            margin-right: 4.1px;
            margin-bottom: 8px;

            &.checked span {
                background-color: $primary!important;
                @if not ($darkMode) {
                    color: $white;
                }
            }

            &:hover a span {
                background-color: $gray-300;
                color: $gray-900;
            }

            span {
                border: 1px solid $gray-300;
                border-radius: 50%;
                font-size: 1.2em!important;
                font-weight: lighter;
                color: $secondary;
                height: 35px;
                width: 35px;
                padding: 5px;
                display: flex;
                flex-flow: column wrap;
            }


            .noletter {
                background-color: $gray-300!important;
                border-color: $gray-300!important;
            }

        }


        

        
    }

    
}