
        
        // DO NOT EDIT THIS FILE DIRECTLY - EDIT FROM EVOSTRAP-ICONS SOURCES ONLY

        $icon-light: #a2a1a2 !default;
        $icon-dark: #323132 !default;

        .es_icon-light {
            color: $icon-light
        };
        .es_icon-dark {
            color: $icon-dark
        };
  

        button, a, [type="button"] {
            &:hover {
                .es_icon-light,
                .es_icon-dark {
                    color: inherit
                };
            }
        }

        [aria-expanded="true"] > .es_icon[data-show-expanded="true"],
        [aria-expanded="true"] > .input-group-append .es_icon[data-show-expanded="true"],
        [aria-expanded="true"] > .es_icon-container .es_icon[data-show-expanded="true"] {
            display: inline-block;
        }

        [aria-expanded="true"] > .es_icon[data-show-expanded="false"],
        [aria-expanded="true"] > .input-group-append .es_icon[data-show-expanded="false"],
        [aria-expanded="true"] > .es_icon-container .es_icon[data-show-expanded="false"] {
            display: none;
        }

        [aria-expanded="false"] > .es_icon[data-show-expanded="true"],
        [aria-expanded="false"] > .input-group-append .es_icon[data-show-expanded="true"],
        [aria-expanded="false"] > .es_icon-container .es_icon[data-show-expanded="true"] {
            display: none;
        }

        [aria-expanded="false"] > .es_icon[data-show-expanded="false"],
        [aria-expanded="false"] > .input-group-append .es_icon[data-show-expanded="false"],
        [aria-expanded="false"] > .es_icon-container .es_icon[data-show-expanded="false"] {
            display: inline-block;
        }

        .es_icon {
            display: inline-block;
        }

        .es_icon-16 {
            width: 16px !important;
            height: 16px !important;
            
        }

        .es_icon-24 {
            width: 24px !important;
            height: 24px !important;
            
        }
        .es_icon-32 {
            width: 32px !important;
            height: 32px !important;
            
        }
        .es_icon-48 {
            width: 48px !important;
            height: 48px !important;
            
        }
        .es_icon-64 {
            width: 64px !important;
            height: 64px !important;
        }

        .es_icon-flip-x {
            transform: scaleX(-1)
        }

        .es_icon-flip-y {
            transform: scaleY(-1)
        }

        .es_icon-rotate-90 {
            transform: rotate(90deg)
        }

        .es_icon-rotate-180 {
            transform: rotate(180deg)
        }

        .es_icon-rotate-270 {
            transform: rotate(270deg)
        }

        .es_icon-inner {
            fill: currentColor;
            stroke: inherit;
        }  
     
            .es_icon-arrow {
                width: 10px;
                height: 6.8px;
            } 
        
            .es_icon-assistant {
                width: 24px;
                height: 24px;
            } 
        
            .es_icon-bars {
                width: 15px;
                height: 12px;
            } 
        
            .es_icon-blog-star {
                width: 17.3px;
                height: 16.8px;
            } 
        
            .es_icon-blog {
                width: 20px;
                height: 20px;
            } 
        
            .es_icon-calendar {
                width: 20.6px;
                height: 21.5px;
            } 
        
            .es_icon-calendar2 {
                width: 17.7px;
                height: 20px;
            } 
        
            .es_icon-calendar3 {
                width: 15.6px;
                height: 15.6px;
            } 
        
            .es_icon-check {
                width: 16.1px;
                height: 12.6px;
            } 
        
            .es_icon-close-sm {
                width: 11.06px;
                height: 11.06px;
            } 
        
            .es_icon-close {
                width: 13.06px;
                height: 13.06px;
            } 
        
            .es_icon-collapse {
                width: 26px;
                height: 26px;
            } 
        
            .es_icon-doc {
                width: 20px;
                height: 14.8px;
            } 
        
            .es_icon-ep-logo-w {
                width: 79.14px;
                height: 43.1px;
            } 
        
            .es_icon-ep-logo {
                width: 79.14px;
                height: 43.1px;
            } 
        
            .es_icon-expand {
                width: 26px;
                height: 26px;
            } 
        
            .es_icon-eye {
                width: 13.6px;
                height: 9.1px;
            } 
        
            .es_icon-facebook-color {
                width: 7.8px;
                height: 17.4px;
            } 
        
            .es_icon-facebook-outline {
                width: 8.85px;
                height: 18.4px;
            } 
        
            .es_icon-facebook {
                width: 7.8px;
                height: 17.4px;
            } 
        
            .es_icon-fast-forward {
                width: 34.9px;
                height: 18px;
            } 
        
            .es_icon-fax {
                width: 18px;
                height: 18.4px;
            } 
        
            .es_icon-flickr-color {
                width: 19.4px;
                height: 9px;
            } 
        
            .es_icon-flickr {
                width: 19.4px;
                height: 9px;
            } 
        
            .es_icon-geolocalization {
                width: 12.4px;
                height: 20.62px;
            } 
        
            .es_icon-google-color {
                width: 17px;
                height: 10.6px;
            } 
        
            .es_icon-google {
                width: 17px;
                height: 10.6px;
            } 
        
            .es_icon-graphic-tag {
                width: 20px;
                height: 14.1px;
            } 
        
            .es_icon-graphic {
                width: 17.8px;
                height: 15.3px;
            } 
        
            .es_icon-headphone {
                width: 11.91px;
                height: 10.5px;
            } 
        
            .es_icon-image {
                width: 18.8px;
                height: 14.1px;
            } 
        
            .es_icon-info {
                width: 6.7px;
                height: 18.8px;
            } 
        
            .es_icon-instagram-color {
                width: 17.2px;
                height: 17.2px;
            } 
        
            .es_icon-instagram {
                width: 17.2px;
                height: 17.2px;
            } 
        
            .es_icon-less {
                width: 9px;
                height: 1px;
            } 
        
            .es_icon-linkedin-color {
                width: 14.2px;
                height: 13.7px;
            } 
        
            .es_icon-linkedin {
                width: 14.2px;
                height: 13.7px;
            } 
        
            .es_icon-list {
                width: 19px;
                height: 19px;
            } 
        
            .es_icon-lock {
                width: 12.2px;
                height: 14px;
            } 
        
            .es_icon-mail-outline {
                width: 15px;
                height: 10px;
            } 
        
            .es_icon-mail {
                width: 15px;
                height: 10px;
            } 
        
            .es_icon-minus {
                width: 16px;
                height: 0.97px;
            } 
        
            .es_icon-mobile {
                width: 11.4px;
                height: 19.2px;
            } 
        
            .es_icon-more {
                width: 9px;
                height: 9px;
            } 
        
            .es_icon-pdf {
                width: 20px;
                height: 14.8px;
            } 
        
            .es_icon-pen {
                width: 12px;
                height: 11.9px;
            } 
        
            .es_icon-phone {
                width: 16.3px;
                height: 16.2px;
            } 
        
            .es_icon-pinterest-color {
                width: 15.92px;
                height: 16.7px;
            } 
        
            .es_icon-pinterest {
                width: 15.92px;
                height: 16.7px;
            } 
        
            .es_icon-planet-outline {
                width: 20px;
                height: 20px;
            } 
        
            .es_icon-planet {
                width: 20px;
                height: 20px;
            } 
        
            .es_icon-play-mini {
                width: 8.1px;
                height: 9px;
            } 
        
            .es_icon-play-outline {
                width: 18.67px;
                height: 20.77px;
            } 
        
            .es_icon-play {
                width: 15px;
                height: 16.6px;
            } 
        
            .es_icon-plus-bold {
                width: 18.1px;
                height: 18px;
            } 
        
            .es_icon-plus {
                width: 16px;
                height: 16px;
            } 
        
            .es_icon-publish {
                width: 14.8px;
                height: 14.8px;
            } 
        
            .es_icon-quote {
                width: 42.9px;
                height: 31.8px;
            } 
        
            .es_icon-reddit-color {
                width: 19.9px;
                height: 16.6px;
            } 
        
            .es_icon-reddit {
                width: 19.9px;
                height: 16.6px;
            } 
        
            .es_icon-reload {
                width: 11.6px;
                height: 11.4px;
            } 
        
            .es_icon-search {
                width: 17.08px;
                height: 18.69px;
            } 
        
            .es_icon-slash {
                width: 4.3px;
                height: 9px;
            } 
        
            .es_icon-snapchat-color {
                width: 17.2px;
                height: 16.17px;
            } 
        
            .es_icon-snapchat {
                width: 16.8px;
                height: 15.77px;
            } 
        
            .es_icon-soundcloud-color {
                width: 24px;
                height: 10.9px;
            } 
        
            .es_icon-soundcloud {
                width: 24px;
                height: 10.9px;
            } 
        
            .es_icon-spinner-stars {
                width: 20px;
                height: 19.87px;
            } 
        
            .es_icon-spinner {
                width: 20px;
                height: 20.77px;
            } 
        
            .es_icon-step-forward {
                width: 20.1px;
                height: 18px;
            } 
        
            .es_icon-tag {
                width: 11.4px;
                height: 11.4px;
            } 
        
            .es_icon-telegram-color {
                width: 14.2px;
                height: 11.77px;
            } 
        
            .es_icon-telegram {
                width: 14.2px;
                height: 11.77px;
            } 
        
            .es_icon-thumb {
                width: 19px;
                height: 19px;
            } 
        
            .es_icon-trash {
                width: 10.6px;
                height: 12.1px;
            } 
        
            .es_icon-twitter-color {
                width: 14.25px;
                height: 11.67px;
            } 
        
            .es_icon-twitter-outline {
                width: 16.95px;
                height: 14.34px;
            } 
        
            .es_icon-twitter {
                width: 14.25px;
                height: 11.67px;
            } 
        
            .es_icon-unpublish {
                width: 14.8px;
                height: 14.8px;
            } 
        
            .es_icon-vimeo-color {
                width: 14.15px;
                height: 12.1px;
            } 
        
            .es_icon-vimeo {
                width: 14.15px;
                height: 12.1px;
            } 
        
            .es_icon-warning {
                width: 18.05px;
                height: 15.06px;
            } 
        
            .es_icon-whatsapp-color {
                width: 17px;
                height: 17px;
            } 
        
            .es_icon-whatsapp {
                width: 17px;
                height: 17px;
            } 
        
            .es_icon-xml {
                width: 20.04px;
                height: 15.01px;
            } 
        
            .es_icon-youtube-color {
                width: 18.2px;
                height: 11.89px;
            } 
        
            .es_icon-youtube {
                width: 18.2px;
                height: 11.89px;
            } 
        