:focus {
	outline-color: $primary;
}

//──── Input group btn ───────────────────────────────────────────────────────────────────
.input-group {
	> .input-group-append > .erpl_icon {
		display: flex;
		align-items: center;
		padding: 0.375rem 0.75rem;
		margin-bottom: 0;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		white-space: nowrap;
		@if not ($darkMode) {
			background-color: $white;
		}
		border: 1px solid $border-color;
		border-left: 0;
		border-radius: 0.25rem; 
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		position: relative;
	}

	.form-control.form-control-sm + .input-group-append > .erpl_icon {
		padding: 0rem .5rem
	}
}

.input-group input:disabled + .input-group-append .erpl_icon {
	background: $input-disabled-bg;
}

.form-control.error + .input-group-append .erpl_icon {
	border-color: $danger
}

.form-control.valid + .input-group-append .erpl_icon {
	border-color: $success
}
// Clear fonction
input.form-control.clearable + .input-group-append .erpl_icon:after {
	transform: rotate(45deg);
	background-position: -1px -93px;
    width: 16px;
	height: 16px;
	cursor: pointer;
} 


// Fix Bootstrap valid/invalid message
.input-group.is-invalid ~ {
	.invalid-feedback {
		display: block;
	}
  }
  
  .input-group.is-valid ~ {
	.valid-feedback {
		display: block;
	}
  }
  
  
//──── Font familly ──────────────────────────────────────────────────────────────────────
.font-familly-sans-serif {
	font-family: $font-family-sans-serif
}
.font-familly-serif {
	font-family: $font-family-serif
}



//──── List group ────────────────────────────────────────────────────────────────────────
.list-group-item:first-child {
    z-index: 2;
}

.list-group-item.active {
	color: inherit;
    z-index: inherit;
    background: inherit;
    border-color: rgba(0, 0, 0, 0.125);
}

.list-group-item.active:after {
	content: "";
    display: block;
    width: 4px;
    background: $primary;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
}

.list-group-item-action {
    color: $primary;
}

.list-group-item-action span {
    display: block;
}


//──── ERPL STICKY ───────────────────────────────────────────────────────────────────────
.position-sticky {
	top: 60px
}

//──── AGENDA ───────────────────────────────────────────────────────────────────────
.erpl_agenda-date {
	font-size: 1.2rem;
	font-family: Georgia, Garamond, serif;
	}

//──── SUBMENU ──────────────────────────────


header .erpl_menu ul > li > a > span, header .erpl_menu ul > li > div > span:not(.select2) {
    margin-right: 7px;
}

header .erpl_menu ul ul {
    min-width: 240px;
}

header .erpl_menu ul > li > a > span.current-nav {
    height: 40px;
}

.border-xs-bottom{
	border-bottom:1px solid #d1d3d4!important;
}  