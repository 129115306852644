//──── ERPL SEARCH RESULTS ───────────────────────────────────────────────────────────────

.erpl_search-results .erpl_search-results-item {
	position: relative
}

.erpl_search-results .erpl_search-results-item  .erpl_search-results-item-subtitle [class^="erpl_search-results-item-subtitle-"] + [class^="erpl_search-results-item-subtitle-"]:before {
 content: " – ";
}




 
//──── GLOBAL SEARCH ────────────────────────────────────────────────────────────────


//──── form global search ────────────────────────────────────────────────────────────────

#globalsearch .form-control {
	font-size: 2.4em;
	font-family: Georgia,Garamond,serif;
	padding:7px;
}

#globalsearch .btn.btn-primary.align-items-center.d-flex {
    width: 59px;
}


#globalsearch i.erpl_icon {
    margin-left: 6px;
}

.erpl_globalsearch-criteria-item-title{
	margin-bottom:15px;
}


.erpl_globalsearch-criteria-item-content li{
	padding-left:5px;
	
}

.erpl_globalsearch-criteria-item-content.bg-light{
	padding: 8px 5px 1px 8px;
}

.erpl_globalsearch-criteria-item-content.bg-light ul{
  margin-left:-26px ;
  line-height: 1.8em!important;

}

@media (max-width: map-get($map: $grid-breakpoints, $key: md)-1) {
	.erpl_globalsearch-criteria-item-content.bg-light {
		// margin-top: 1.3em !important;
}
}

//──── index search ────────────────────────────────────────────────────────────────
#indexsearch .form-check {
    margin-bottom: 2px;
}
#indexsearch .form-check-input2 {
    margin-left: -1.25rem;

}


//──── sort results ────────────────────────────────────────────────────────────────

 
.erpl_search-results-item-body em{
	font-weight:bold;
	font-style: italic;
} 

#sortresults .criteria.checked {
	background-color: $primary;
	@if ($darkMode) {
		color: $black;
	} @else { 
		color: $white;
	 }
	width: 50%;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	float:left;
	height:43px;
	display:flex;
	padding:4px;

}

#sortresults .criteria {
	@if ($darkMode) {
		background-color: $gray-900;
	} @else { 
		background-color: $white;
		border: 1px solid #d1d3d4;
	 }
	width: 50%;
	border-radius: 4px;
	cursor: pointer;
	text-align: center;
	color: $primary;
	float: left;
	height: 43px;
	display:flex;
	padding:4px;
	
}

#sortresults .criteria:hover {
	background-color:#f1f2f2;
	color: #505154;
	
   
}

.criteria .check-label{
	font-size: 0.8em !important; 
	cursor:pointer;
	margin:auto;
}


.criteria input{
	opacity:0;  
	display: none;
}
