@if ($darkMode) {
	//──── SOCIAL SHARE HORIZONTAL ──────────────────────────────

	.erpl_social-share-horizontal {
		display: flex;
		flex-wrap: wrap !important;
	}


	.erpl_social-share-horizontal a {
		height: 35px;
		width: 35px;
		@if ($darkMode) {
			border: 1px solid $gray-200;
		} @else { 
			border: 1px solid #1e1e1f;
		 }
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #eeeff0;
	}


	.erpl_social-share-horizontal a:hover {
		background: $primary;
	}


	// black to white icon



	.erpl_social-share-horizontal a:hover i.erpl_icon-mail-dark:after {
		background-position: 254px 392px;
		width: 20px;
		height: 21px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-website-dark::after {
		background-position: -27px 352px;
		width: 20px;
		height: 21px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-facebook-dark:after {
		background-position: -135px -44px;
		width: 9px;
		height: 18px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-twitter-dark:after {
		background-position: -132px -146px;
		width: 15px;
		height: 13px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-youtube-dark::after {
		background-position: -130px -126px;
		width: 19px;
		height: 12px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-linkedin-dark:after {
		background-position: -132px -105px;
		width: 15px;
		height: 14px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-instagram-dark::after {
		background-position: -130px -163px;
		width: 19px;
		height: 18px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-google-dark:after {
		background-position: -131px -226px;
		width: 17px;
		height: 12px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-pinterest-dark:after {
		background-position: -131px -183px;
		width: 17px;
		height: 18px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-soundcloud-dark::after {
		background-position: -31px -327px;
		width: 25px;
		height: 12px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-flickr-dark::after {
		background-position: -129px -87px;
		width: 21px;
		height: 10px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-blog-dark::after {
		background-position: -27px -343px;
		width: 20px;
		height: 19px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-blog-star-dark::after {
		background-position: -122px -343px;
		width: 19px;
		height: 18px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-calendar-dark::after {
		background-position: 91px -306px;
		width: 18px;
		height: 21px;

	}

}

@else {

	//──── SOCIAL SHARE HORIZONTAL ──────────────────────────────

	.erpl_social-share-horizontal {
		display: flex;
		flex-wrap: wrap !important;
	}


	.erpl_social-share-horizontal a {
		height: 35px;
		width: 35px;
		border: 1px solid $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #eeeff0;
	}


	.erpl_social-share-horizontal a:hover {
		background: $primary;
	}


	// black to white icon



	.erpl_social-share-horizontal a:hover i.erpl_icon-mail-white:after {
		background-position: 254px 392px;
		width: 20px;
		height: 21px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-website-white::after {
		background-position: -27px 352px;
		width: 20px;
		height: 21px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-facebook-white:after {
		background-position: -156px -44px;
		width: 9px;
		height: 18px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-twitter-white:after {
		background-position: -153px -146px;
		width: 15px;
		height: 13px;
	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-youtube-white::after {
		background-position: -130px -126px;
		width: 19px;
		height: 12px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-linkedin-white:after {
		background-position: -153px -105px;
		width: 15px;
		height: 14px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-instagram-white::after {
		background-position: -130px -163px;
		width: 19px;
		height: 18px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-google-white:after {
		background-position: -131px -226px;
		width: 17px;
		height: 12px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-pinterest-white:after {
		background-position: -131px -183px;
		width: 17px;
		height: 18px;
	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-soundcloud-white::after {
		background-position: -31px -327px;
		width: 25px;
		height: 12px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-flickr-white::after {
		background-position: -129px -87px;
		width: 21px;
		height: 10px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-blog-white::after {
		background-position: -27px -343px;
		width: 20px;
		height: 19px;

	}

	.erpl_social-share-horizontal a:hover i.erpl_icon-blog-star-white::after {
		background-position: -122px -343px;
		width: 19px;
		height: 18px;

	}


	.erpl_social-share-horizontal a:hover i.erpl_icon-calendar-white::after {
		background-position: 91px -306px;
		width: 18px;
		height: 21px;

	}
}