//========================================================================================

/*                                                                                      *
 *                                        Footer                                        *
 *                                                                                      */

//========================================================================================
footer {
    color: $white
}

footer a {
    color: $white
}

footer a:hover {
    color: $white
}

footer .subtitle {
    font-weight: bold;
    color: #9d9fa2;
}

footer .link-group {
    line-height: normal
}

footer .link-group a {
    color: $white;
}

footer .erpl_social-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

footer .erpl_social-links a {
    width: 35px;
    height: 35px;
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .erpl_social-links a i {
    transition: 0.35s;
}

footer .erpl_social-links a:hover i {
    transform: scale(1.4)
}

footer .separator:after {
    border-color: #b1b3b6
}

footer .footerLinkToggle i {
    background: $white;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Gestion de la navigation du pied de page sans JS */

@media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
    body[data-js=true] footer .erpl_nojs-close-menu {
        display: none
    }
    body[data-js=false] footer #footerLinks .collapse {
        display: none;
    }
    body[data-js=false] footer .erpl_nojs-close-menu {
        display: none;
    }
    body[data-js=false] footer .erpl_toggle-menu {
        display: block
    }
    body[data-js=false] footer #website-links:target .collapse {
        display: block
    }
    body[data-js=false] footer #website-links:target .erpl_nojs-close-menu {
        display: block
    }
    body[data-js=false] footer #website-links:target .erpl_toggle-menu {
        display: none
    }
    body[data-js=false] footer #europarl-links:target .collapse {
        display: block
    }
    body[data-js=false] footer #europarl-links:target .erpl_nojs-close-menu {
        display: block
    }
    body[data-js=false] footer #europarl-links:target .erpl_toggle-menu {
        display: none
    }
}


/* erpl_footer */

.erpl_footer {
    .subtitle {
        font-weight: normal;
        color: #9d9fa2;
    }
    .link-group a {
        font-weight: 700;
    }
    .link-group a span:active {
        border: 3px dotted $white;
    }
    .erpl_social-links a:active {
        border: 3px dotted $black;
    }
    #information-links a span:active {
        border: 3px dotted $white;
    }
}

//──── erpl_footer-top ────────────────────────────────────────────────────────────────────────
.erpl_footer-top {
    .container>.footer-top-content {
        border-top: none;
    }
    .erpl_share-links ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    .erpl_share-links ul li a {
        @if ($darkMode) {
            border: 1px solid $gray-200;
        } @else { 
            border: 1px solid #1e1e1f;
         }
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .erpl_share-links ul li a:hover {
        background: $primary
    }
    .erpl_share-links ul li a i {
        transform: scale(.9)
    }
    .erpl_share-links ul li a.facebook:hover i.erpl_icon:after {
        background-position: -135px -44px;
        width: 9px;
        height: 18px;
    }
    .erpl_share-links ul li a.twitter:hover i.erpl_icon:after {
        background-position: -132px -146px;
        width: 15px;
        height: 13px;
    }
    .erpl_share-links ul li a.linkedin:hover i.erpl_icon:after {
        background-position: -132px -105px;
        width: 15px;
        height: 14px;
    }
    .erpl_share-links ul li a.google:hover i.erpl_icon:after {
        background-position: -131px -226px;
        width: 17px;
        height: 12px;
    }
    .erpl_share-links ul li a.whatsapp:hover i.erpl_icon:after {
        background-position: -23px -586px;
        width: 18px;
        height: 18px;
    }
    .erpl_share-links .erpl_title-h5 {
        font-weight: normal;
        margin: 0;
    }
    .erpl_share-links .erpl_title-h5:before {
        height: 1px;
        margin-top: 3px;
        background-color: #1e1e1f;
        content: " ";
        display: block;
    }
    .erpl_share-links .erpl_title-h5 span {
        vertical-align: middle;
        position: relative;
        top: -.75em;
        background: $white;
        padding: 0 .5rem;
    }
    .erpl_horizontal-links div,
    .erpl_horizontal-links div a {
        display: block;
    }
    .erpl_horizontal-links div+div:before {
        content: "";
        @if ($darkMode) {
            background-color: $gray-600;
        } @else { 
            background-color: #1e1e1f;
        }
        width: 1px;
        display: none;
        height: 25px;
        vertical-align: middle;
        margin: 0 5px
    }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: md)) {
    .erpl_footer-top .container>.erpl_footer-top-content {
        border-top: 1px solid;
    }
    .erpl_footer-top .erpl_horizontal-links div,
    .erpl_footer-top .erpl_horizontal-links div a {
        display: inline-block;
    }
    .erpl_footer-top .erpl_horizontal-links div+div:before {
        display: inline-block;
    }
    .erpl_footer-top .erpl_share-links .erpl_title-h5:before {
        display: none;
    }
    .erpl_footer-top .erpl_share-links .erpl_title-h5 span {
        position: relative;
        top: inherit;
        background: inherit;
        padding: inherit;
    }
}